export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你好， 世界"])},
  "Header-Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCW 陈萍律师行  一站式法律服务"])},
  "Menu-Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "Menu-AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
  "Menu-OurServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务范围"])},
  "Menu-Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团队介绍"])},
  "Menu-Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "Our-Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的团队"])},
  "Staff-Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团队介绍"])},
  "StaffName-PingChen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["陈萍律师"])},
  "StaffTitle-PingChen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director, Barrister & Solicitor (NZ)"])},
  "StaffPing-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新西兰高等法院律师，陈萍律师行创始人，前移民法律委员会委员。新西兰法律硕士/中国法学学士，具有中国检察机关和新西兰本地丰富的法律实战经验，贯通中西法律，擅长跨文化沟通谈判，以独特魅力让陈萍律师行凝聚了大批20年以上经验的各个领域法律专家。\n\n为了让更多华人了解当地的法律，陈萍律师先后创办主持了广播,电视,多媒体等多档中文法律节目。曾受新西兰行动党党魁 David Seymour特邀在电视节目中对\"三振法案\"做出精彩的法律点评。2020年新冠疫情期间，陈萍律师采访了前总理John Key爵士和新西兰前纽航CEO、现任国家党党魁Christopher Luxon，为解决当时最为敏感的口罩和种族歧视问题发声，此节目受到了新西兰两大主流英文媒体的全面报道，央视四台也曾就陈萍律师对中新法律文化交流所做出的贡献做了专题报道。"])},
  "StaffName-DrKeeTee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr. Key"])},
  "StaffTitle-DrKeeTee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director, Barrister & Solicitor (NZ), Notary Public"])},
  "StaffName-Annette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette"])},
  "StaffTitle-Annette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrister & Solicitor (NZ)"])},
  "StaffName-Gary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gary"])},
  "StaffTitle-Gary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrister"])},
  "StaffName-Donnelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnelle"])},
  "StaffTitle-Donnelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "StaffName-Jeff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeff"])},
  "StaffTitle-Jeff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Consultant"])},
  "StaffName-Doug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doug"])},
  "StaffTitle-Doug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "StaffName-Olinda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olinda"])},
  "StaffTitle-Olinda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Consultant"])},
  "StaffName-Sean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sean"])},
  "StaffTitle-Sean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "StaffName-Colin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colin"])},
  "StaffTitle-Colin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Consultant"])},
  "StaffName-Lei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lei"])},
  "StaffTitle-Lei": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eastern Office Manager"]), _normalize(["Registered Legal Executive"])])},
  "StaffName-Eric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eric"])},
  "StaffTitle-Eric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Development Manager"])},
  "StaffName-Yue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yue"])},
  "StaffTitle-Yue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Executive"])},
  "StaffName-Katharine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katharine"])},
  "StaffTitle-Katharine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law Clerk"])},
  "StaffName-William": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["William"])},
  "StaffTitle-William": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust Accountant"])},
  "StaffName-LiWei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi"])},
  "StaffTitle-LiWei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Assistant"])},
  "StaffName-ChiXu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Xu"])},
  "StaffTitle-ChiXu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law Clerk"])},
  "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于陈萍律师行"])},
  "about-us-why-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为什么要选择我们？"])},
  "about-us-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到陈萍律师行！<br />我们是一家实战经验丰富，专业水平领先的综合性律师行，分别在奥克兰顶尖商区 New Market 和华人喜爱的东区 Meadowlands 设有分行。<br />我们的团队是由各具专长的法律专家组成，法律服务涵盖地产，商业，移民，家庭法，雇佣法，民事/刑事诉讼，国际公证等多个领域。<br />我们用您熟悉的语言为您提供量身定制的法律解决方案，致力于帮助华人企业和个人在新西兰顺利落户，移民，投资，并促进新西兰主流社会了解，接纳，支持华人企业及个体的创业和发展。<br />陈萍律师行以谦逊的态度，实干的精神和广泛的人脉关系助力客户保驾护航。我们赢得了社会各界的高度认可和尊重，并成为新西兰华人社区首选的法律服务机构！<br />选择陈萍律师行，您将得到专业，及时，高效，周到的法律服务，让您的海外生活和事业更加美好！"])},
  "our-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的服务"])},
  "our-service-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务内容"])},
  "our-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的目标"])},
  "our-goal-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用您熟悉的语言为您提供及时,专业,高效的法律服务！"])},
  "our-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的价值观"])},
  "our-vision-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独立思考，紧抓重点，谨慎思辨，诚信保密的办案原则，不畏强手，不惧困难，以坚持捍卫当事人法律权利为办案之本"])},
  "property-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房产交易"])},
  "property-settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房产过户"])},
  "property-due-diligence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合同审查"])},
  "property-refinance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新贷款"])},
  "property-subdivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地产分割"])},
  "property-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源许可"])},
  "property-keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房产上锁"])},
  "commercial-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业法律"])},
  "commercial-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生意买卖"])},
  "commercial-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土地分割"])},
  "commercial-rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业租约"])},
  "commercial-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股权转让"])},
  "commercial-merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收购合并"])},
  "commercial-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合同起草"])},
  "family-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家庭法律"])},
  "family-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["婚姻财产"])},
  "family-divorce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分居离婚"])},
  "family-guardianship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监护权申请"])},
  "family-prenuptial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["婚前协议"])},
  "family-restraining-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止令申请"])},
  "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信托遗嘱"])},
  "trust-family-trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家庭信托"])},
  "trust-will": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遗嘱"])},
  "immigration-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移民法律"])},
  "immigration-visa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签证申请"])},
  "immigration-investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资移民"])},
  "immigration-family-union": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家庭团聚"])},
  "immigration-overstay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期拘留"])},
  "immigration-deportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤销遣返令"])},
  "immigration-complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移民申诉"])},
  "immigration-judicial-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["司法审核"])},
  "employment-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雇佣法律"])},
  "employment-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雇佣合同"])},
  "employment-dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雇佣纠纷"])},
  "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["诉讼纠纷"])},
  "dispute-civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["民事诉讼"])},
  "dispute-commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刑事诉讼 - 国王御用大律师 Paul Dacre K.C."])},
  "liability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["债务追讨"])},
  "liability-personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人债务"])},
  "liability-commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企业债务"])},
  "liability-bankruptcy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["破产清算"])},
  "notary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际公证"])},
  "notary-international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际公证"])},
  "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知识产权法"])},
  "ip-trademarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商标注册"])},
  "ip-copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权保护"])},
  "criminal-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刑法专家（K.C. 国王御用大律师）"])},
  "criminal-drink-driving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒驾"])},
  "criminal-drug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毒品"])},
  "criminal-harm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伤害"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "contact-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "OnlinePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线上支付"])}
}